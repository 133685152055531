import { twMerge } from "tailwind-merge";
import React, { HTMLAttributes, useState } from "react";
import {
  TitleJost,
  DividerUnderline,
  TextPoppins,
} from "@brightstarstudios/components";
import { Border } from "components/Border";
import { SOCIALS } from "data/socials";
import { IndicatedIcon } from "components/IndicatedIcon";

// Thin wrapper around IndicatedIcon to add hover effect
const AnchorIndicatedIcon: React.FC<{
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  href: string;
}> = ({ Icon, href }) => {
  const [showIndicator, setShowIndicator] = useState<boolean>(false);
  const iconClass = showIndicator ? "text-primary-red" : "";

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <IndicatedIcon
        Icon={Icon}
        iconClassName={twMerge("duration-500", iconClass)}
        showIndicator={showIndicator}
        indicatorClassName={"text-primary-red"}
        className="block h-16 w-16"
        onMouseOver={() => setShowIndicator(true)}
        onMouseLeave={() => setShowIndicator(false)}
      />
    </a>
  );
};

export const BlockSocials: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  className = "",
}) => {
  return (
    <Border className={className}>
      <div className="flex flex-col items-center justify-start gap-6 px-6 py-12 text-center lg:px-12 lg:py-16">
        <TitleJost
          size="large"
          variant="h2"
          className="font-extrabold uppercase lg:text-6xl"
        >
          Stay Updated
        </TitleJost>
        <DividerUnderline className="my-0 w-full" />
        <TextPoppins size="medium" className="mb-6 max-w-md">
          Be a part of the most wonderful community on the internet and stay
          up-to-date with all things Ember Sword!
        </TextPoppins>

        <div className="grid w-full max-w-lg grid-cols-3 justify-items-center gap-4 md:grid-cols-4 lg:grid-cols-6 lg:justify-items-center">
          {SOCIALS.map(({ link, Icon }) => (
            <AnchorIndicatedIcon key={link} Icon={Icon} href={link} />
          ))}
        </div>
      </div>
    </Border>
  );
};
