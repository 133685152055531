import { twMerge } from "tailwind-merge";
import React from "react";
import patternCountoursUrl from "/public/svgs/patterns/pattern-contours.svg?url";
import patternHexUrl from "/public/svgs/patterns/pattern-hex.svg?url";

type BgPatternProps = {
  pattern: "hex" | "contour-lines";
} & React.HTMLAttributes<HTMLDivElement>;

export const BgPattern: React.FC<BgPatternProps> = ({
  pattern,
  className = "",
  ...rest
}) => {
  const backgroundImageUrl = (
    pattern === "hex" ? patternHexUrl : patternCountoursUrl
  ).src;
  const backgroundImage = `url(${backgroundImageUrl})`;

  const backgroundSize = pattern === "hex" ? "256px 224px" : "1118px 1024px";

  return (
    <div
      style={{
        backgroundImage,
        backgroundSize,
      }}
      className={twMerge(
        "pointer-events-none absolute inset-0 bg-[length:1118px_1024px] opacity-[0.03]",
        className,
      )}
      {...rest}
    />
  );
};
