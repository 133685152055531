import { twMerge } from "tailwind-merge";
import React, { useEffect, useRef } from "react";
import bannerActive from "assets/lotties/banner-active.json";

interface AnimatedBannerReelProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: "left" | "right";
  selectedIndex: number;
  containerClass?: string;
}

import dynamic from "next/dynamic";
import { LottieRefCurrentProps } from "lottie-react";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

export const AnimatedBannerReel: React.FC<AnimatedBannerReelProps> = ({
  direction = "left",
  className = "",
  selectedIndex = 0,
  containerClass = "",
  children,
  ...rest
}) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.playSegments([30, 0]);
    }
  }, [selectedIndex]);

  const bannerReelClass = direction === "right" ? "" : "rotate-180";

  const translatePercentage =
    selectedIndex * (100 / React.Children.count(children));

  const transateStyle: React.CSSProperties = {
    transform: `translateY(-${translatePercentage}%)`,
  };

  return (
    <div className={twMerge("relative w-full", className)} {...rest}>
      <div
        className={twMerge("relative w-full mask-linear-r", bannerReelClass)}
      >
        <Lottie
          lottieRef={lottieRef}
          animationData={bannerActive}
          loop={false}
          autoplay={false}
        />
      </div>

      <div
        style={transateStyle}
        className="absolute inset-x-0 top-0 transition-transform duration-500"
      >
        {React.Children.map(children, (child, i) => {
          return (
            <div
              key={i}
              className={twMerge(
                "flex w-full items-center justify-center",
                containerClass,
              )}
            >
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};
