import { BgPattern } from "components/BgPattern";
import { BlockSocials } from "components/blocks/BlockSocials";
import { Container } from "components/Container";
import { DividerText } from "@brightstarstudios/components";
import { twJoin } from "tailwind-merge";

interface BlockCommunityProps {
  color: "light" | "dark";
}
export const BlockCommunity: React.FC<BlockCommunityProps> = ({ color }) => {
  const isLight = color === "light";

  return (
    <>
      <BgPattern
        pattern="hex"
        className={twJoin(
          "bottom-0 top-1/2 z-10 mask-linear-t",
          isLight && "invert filter",
        )}
      />

      <Container>
        <DividerText
          text="Community"
          className="my-14"
          color={isLight ? "red" : "teal"}
        />
        <BlockSocials
          className={isLight ? "text-tertiary-600" : "fill-white text-white"}
        />
        {/* <div className="grid grid-cols-1 gap-12 mb-16 lg:grid-cols-2">
          <BlockSocials
            className={isLight ? "text-tertiary-600" : "fill-white text-white"}
          />
          <BlockNewsletter
            buttonType={isLight ? "blue" : "red"}
            className={
              isLight
                ? "from-primary-teal-500 to-primary-teal-600 text-tertiary-600"
                : "from-orange-200 to-primary-red text-white"
            }
          />
        </div> */}
      </Container>
    </>
  );
};
